export default class Common {
  constructor() {
    // ヘッダー右スクロール可動
    const header = document.getElementById('header');
    window.addEventListener('scroll', handleScroll, false);
    function handleScroll() {
      header.style.left = `${-window.pageXOffset}px`;
    }

    // sp版 ナビメニュー
    document.getElementById('nav_btn').onclick = function () {
      document.getElementById('header').classList.toggle('open');
    };

    const elements = [].slice.call(document.getElementsByClassName('nav-anchor'));
    elements.forEach(function (e) {
      e.addEventListener('click', function () {
        document.getElementById('header').classList.toggle('open');
      });
    });
  }
}
